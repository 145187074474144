import $ from 'jquery';

export default () => {
  const isScrolledIntoView = (elem) => {
    let $elem = $(elem);
    let $window = $(window);

    let docViewTop = $window.scrollTop();
    let docViewBottom = docViewTop + $window.height();

    let elemTop = $elem.offset().top;
    let elemBottom = elemTop + $elem.height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  }

  window.onscroll = () => {
    if ($(".line-icon__wrapper").length) {
      if(isScrolledIntoView($(".line-icon__wrapper"))) {
        $(".line-icon__icon").addClass("animated_path")
      }    
    }
  }
};
