import $ from 'jquery';

export default () => {
	if ($('.vote-now__item').length) {
    for (var item of $('.vote-now__item')) {
      if (!$(item).find('.vote-now__cta.gold').length) {
        $(item).find('.vote-now__information').addClass('disabled')
      }
    }
  }
};
