import $ from 'jquery';

export default () => {
  const itemsEndpoint = location.hostname === 'localhost' ? '/data/yawa-questions.json' : '/api/feature/yawa/questions';
  const filtersEndpoint = location.hostname === 'localhost' ? '/data/yawa-categories.json' : '/api/feature/yawa/categories';
  //const maxage = 60 * 60 * 1000;
  const maxage = 0;
  const wrapper = $('[data-yawa-items]');
  const filter = $('[data-yawa-filter]');
  const load = $('[data-yawa-load]');

  if(wrapper.length) {
    let category = [];
    let data = {
      items: [],
    };

    const getFilters = () => {
      $.ajax(filtersEndpoint, {
        success: function(response){
          buildFilters(response);
          getItems(false, {});
        }
      });
    }
    
    const getItems = (useCache, params) => {
      // Load initial data
      const cached = JSON.parse(sessionStorage.getItem('yawa'));
      if(useCache && cached && cached.timestamp >= new Date().getTime() - maxage) {
        //console.info('Use cached', cached);
        data = cached.data;
        buildItems(data.items);
        toggleMore();
      }
      else {
        $.ajax(itemsEndpoint, {
          data: {
            offset: data.items.length,
            category: params.category ? params.category : null,
            expert: params.expert ? params.expert : null,
            max: params.max ? params.max : null,
          },
          success: function(response){
            data.items = data.items.concat(response.Results);
            data.total = response.Total;
            sessionStorage.setItem('yawa', JSON.stringify({
              timestamp: new Date().getTime(),
              data: data,
            }));

            buildItems(params.max ? params.max : null);
            toggleMore();
          }
        });
      }
    }

    const buildFilters = (items) => {
      $(items).each(function(index, item){
        let status = item.Key ? '' : 'checked';
        $(`
          <li>
            <input id="yawa__filter-${index}" type="checkbox" name="filter" value="${item.Key}" ${status}>
            <label for="yawa__filter-${index}">${item.Value}</label>
          </li>
        `).appendTo(filter);
      });

      // Set up filters
      filter.find('input').on('click', function(){
        if($(this).val()) {
          if($(this).is(':checked')) {
            filter.find('input[value=""]').prop('checked', false);
            category.push($(this).val());
          }
          else {
            category.splice(category.indexOf($(this).val()), 1);
            if(!filter.find('input:checked').length) {
              filter.find('input[value=""]').prop('checked', true);
            }
          }
        }
        else {
          filter.find('input').prop('checked', false);
          $(this).prop('checked', true);
          category = [];
        }

        history.replaceState({}, null, `#${category.join(',')}`);
        buildItems();
      });

      // Get initial category
      const hash = location.hash.split('#').pop();
      if(hash) {
        category = hash.split(',');
        $(category).each(function(index, value){
          filter.find(`input[value="${value}"]`).prop('checked', true);
        });
      }
    }

    const buildItems = (max) => {
      let currentId = $('[data-questionid]').attr('data-questionid');

      wrapper.css('opacity', 0);
      setTimeout(function(){
        wrapper.empty();
        let count = 0;

        $(data.items).each(function(index, item){
          let show = true;
          if(category.length) {
            show = false;
            $(item.Category.split('|')).each(function(index, value){
              if(category.indexOf(value) > -1) {
                show = true;
              }
            });
          }
          else if(currentId && currentId == item.Id) {
            show = false;
          }
          else if(max && count == max - 1) {
            show = false;
          }

          if(show) {
            count++;
            $(`
              <a class="yawa-item ${item.Image ? 'yawa-item--with-image' : ''} ${item.Colour}" 
                 href="${item.Url}" data-yawa-item data-yawa-id="${item.Id}" data-yawa-category="${item.Category}">
                <div class="yawa-item__image" style="background-image: url(${item.Image}"></div>
                <div class="yawa-item__content">
                  <p class="yawa-item__prefix">${item.Questioner}</p>
                  <h2 class="yawa-item__title">${item.Question}</h2>
                  <p class="yawa-item__suffix">${item.Responder}</p>
                </div>
              </a>
            `).appendTo(wrapper);
          }
        });

        wrapper.css('opacity', 1);
        $(window).trigger('resize');
      }, 333);
    }

    const toggleMore = () => {
      if(data.items.length < data.total) {
        load.addClass('active');
      }
      else {
        load.removeClass('active');
      }
    }

    const init = () => {
      
      // YAWA index page
      if(filter.length) {
        getFilters(true, {});

        // Set up "Load More"
        load.on('click', function(event){
          event.preventDefault();
          getItems(true, {});
        });
      }
      // YAWA question page - get related questions by category
      else if($('[data-categoryid]').length) {
        getItems(false, {
          category: $('[data-categoryid]').attr('data-categoryid'),
          max: 5,
        });
      }
      // YAWA expert page - get related questions by expert
      else if($('[data-expertid]').length) {
        getItems(false, {
          expert: $('[data-expertid]').attr('data-expertid')
        });
      }
    }

    init();
  }

};
