import $ from 'jquery';

export default () => {
  const form = $('#form--recipes_filters');
  const field = form.find('input[name="query"]');
  const query = field.val();

  // Clear previous search filters if search query has changed
  $('#form--recipes_filters').on('submit', function(event){
    if(query && field.val() != query) {
      $('input[name="page"]').val('');
      $('input[name="facets"]').val('');
    }
  });
};
