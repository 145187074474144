import $ from 'jquery';
import { isMobile } from '../helpers/breakpoints';

export default () => {
  for (var table of $('table')) {
    if ($(table).innerWidth() > $(window).innerWidth()) {
      $(table).wrap('<div class="table_wrapper"></div>')
    }
  }
};
