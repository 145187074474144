import $ from 'jquery';

function initAccordion() {
	$('.accordion').each( function() {
		accordion($(this));
	});
}

function accordion($thisAccordion) {

	let toggles = $thisAccordion.find('.accordion__toggle');

	function removeAllActiveClass(){
		toggles.each( function(){
			const $thisParent = $(this).parent();
			$thisParent.removeClass('active');
			$thisParent.find('.accordion__content').height(0);
		});
	}

	function setActive($thisParent){

		const $thisContent = $thisParent.find('.accordion__content'),
		$thisContentHeight = $thisContent.find('.accordion__content-container').height();

		removeAllActiveClass($thisParent);
		$thisParent.addClass('active');
		$thisContent.height($thisContentHeight);
	}

	function toggle($thisParent){
		const $thisContent = $thisParent.find('.accordion__content');
		if( !$thisParent.hasClass('active') ){
			setActive($thisParent);
		}else {
			$thisParent.removeClass("active");
			$thisContent.height(0);
		}
	}

	function init() {
		toggles.each( function(){
			const thisParent = $(this).parent();
			if ( thisParent.hasClass('active') ){
				setActive( thisParent );
			}
		});
	}

	toggles.on('touch, click', function(e) {
		e.preventDefault();
		toggle($(this).parent());
	});

	// $thisAccordion.removeClass('loading');
	init();
}

export default () => {
	if ($('.accordion').length > 0) {
		initAccordion();
	}
};
