import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';

export default class Search extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      searchResults: null
    }
  }

  componentWillMount() {
  }

  componentDidMount() {
  }

  debounce(a,b,c){var d,e;return function(){function h(){d=null,c||(e=a.apply(f,g))}var f=this,g=arguments;return clearTimeout(d),d=setTimeout(h,b),c&&!d&&(e=a.apply(f,g)),e}}

  handleChange = this.debounce((e) => {
    const element = document.getElementById('yawa-search__keyword');
    const formData = {
      keyword: element.value,
    };
    $.ajax({
      type: 'GET',
      url: '/api/feature/yawa/questionsautocomplete',
      data: formData,
      dataType: 'json',
      success: (response) => {
        console.log(response)
        this.setState({
          searchResults: response
        });
        this.boldWords(formData.keyword, this.state.searchResults.Keyword)
        $('#result-item--last').html('"'+formData.keyword+'"');
      },
      error: (error) => {
        console.log(error)
      }
    })
  }, 1000).bind(this);

  boldWords(question, words) {
    let wordsArray = words.split(' ');
    wordsArray.map((item, i) => {
      item = item.replace(/[^0-9a-z]/gi, '');
      if (item.match("[a-zA-Z]+")) {
        if (new RegExp('\\b'+item+'\\b').test(question)) {
          question = question.replace(item, '<b>'+item+'</b>');
         }
      }
    })
    return(question);
  }

  render() {
    return (
      <div className="yawa-search__wrapper">
        <div className="yawa-search__container">
          <input id="yawa-search__keyword" className="yawa-search__input" placeholder="Search or ask a question" type="text" onChange={this.handleChange.bind(this)} />
          <span className="yawa-search__lens"></span>
            { this.state.searchResults !== null &&
              this.state.searchResults.Keyword !== '' &&
              <div className="yawa-search__results-wrapper">
                <div className="yawa-search__results-container">
                  {
                    this.state.searchResults.Results.map((item,id) => <a className="yawa-search__result-item" href={item.Url}>{ReactHtmlParser(this.boldWords(item.Question, this.state.searchResults.Keyword))}</a>)
                  }
                </div>
                <a className="yawa-search__result-item" href="/dairy-matters/you-ask-we-answer/ask-a-question">
                  Ask <span id="result-item--last"></span>  
                  <svg width="14px" height="31px" viewBox="0 0 14 31" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
                    <path
                      fill="#0C2340"
                      d="M7,20.7645397 C6.29305265,21.6663473 -0.176736838,29.423766 0.530210515,30.3255736 C1.23918024,31.2248088 2.38970037,31.2248088 3.09867009,30.3255736 L13.4697895,17.1335369 C14.1767368,16.2317293 14.1767368,14.7682707 13.4697895,13.8664631 L3.09867009,0.674426386 C2.38970037,-0.224808795 1.23918024,-0.224808795 0.530210515,0.674426386 C-0.176736838,1.57623401 2.39172274,3.5102008 3.09867009,4.41200842 L9.62308945,15.50498 L7,20.7645397 Z"
                    />
                  </svg>
                </a>
              </div> 
            }
        </div>
      </div>
    );
  }

}
