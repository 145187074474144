import $ from 'jquery';

export default () => {

  // remove link style
  for (var singleLink of $('link')) {
    if (singleLink.href.includes('tfaforms')) {
      $(singleLink).remove();
    }
  }

  // remove style tag
  for (var singleStyle of $('style')) {
    $(singleStyle).remove();
  }

  // custom dropdown style and behaviour
  for (var singleSelect of $('.wFormContainer select')) {
    let selectTrigger = '<div class="js-select-trigger select__trigger"><span>Please Select... </span></div>';
    $(selectTrigger).insertBefore(singleSelect);
    $(singleSelect).addClass('js-select');
  }


  $(document).on('change', '.js-select', (e) => {
   $(e.target).prev('.js-select-trigger').find('span').html($(e.target).find(':selected').text());
  });

  // custom textarea to go full width
  for (var singleTextarea of $('.wFormContainer textarea')) {
    $(singleTextarea).closest('.oneField').addClass('full_width');
  }

  // custom checkbox
  $('.wFormContainer input[type="checkbox"]').closest('.inputWrapper').addClass('inputWrapper--checkbox');
  $('.wFormContainer input[type="checkbox"]').closest('.oneField').addClass('full_width');
  /*
  for (var singleCheckbox of $('.wFormContainer input[type="checkbox"]')) {
    //let checkboxTrigger = '<div class="js-checkbox-trigger checkbox__trigger"></div>';
    $(singleCheckbox).closest('.inputWrapper').addClass('inputWrapper--checkbox');
    $(singleCheckbox).closest('.oneField').addClass('full_width');
    //$(checkboxTrigger).insertAfter(singleCheckbox);
  }
  */

  // custom checkbox
  for (var singleCheckbox of $('.wFormContainer input[type="radio"]')) {
    $(singleCheckbox).closest('.choices').addClass('choices--checkbox');
  }

  // custom fieldset
  for (var singleFieldset of $('.wFormContainer fieldset')) {
    var fieldsetContent = $(singleFieldset).html();
    $(singleFieldset).html("<div class='section'>" + fieldsetContent + "</div>")
  }

  // custom file upload
  for (var singleUpload of $('.wFormContainer input[type="file"]')) {
    let checkboxTrigger = '<div class="js-file_upload file_upload"><svg width="18" height="19" xmlns="http://www.w3.org/2000/svg"><path d="M13.397 0c2.448 0 4.602 2.084 4.602 4.852 0 1.364-.425 2.74-1.374 3.78l-8.418 9.226a.598.598 0 0 1-.895.028c-.236-.262-.22-.744.032-.987l8.411-9.225c.702-.77 1.017-1.765 1.017-2.822 0-1.991-1.551-3.498-3.375-3.498-.845 0-1.696.385-2.365 1.122L1.847 12.589c-.398.44-.62.992-.62 1.503 0 .656.188 1.285.614 1.756.823.91 2.117.91 2.94 0 3.031-3.35 6.064-6.694 9.095-10.044.351-.454.366-.985.096-1.326-.388-.337-.875-.3-1.144-.02L4.525 13.57a.598.598 0 0 1-.895.028c-.235-.262-.22-.744.032-.987l8.303-9.113c.975-.863 2.181-.868 2.87.007.775.885.676 2.271-.026 3.181L5.65 16.807c-1.288 1.424-3.39 1.424-4.678 0C.282 16.045 0 15.035 0 14.092c0-.927.385-1.802.984-2.462L10.17 1.517C11.057.539 12.216.002 13.397 0z" fill="#0C2340" fill-rule="evenodd"/></svg><span>Choose file</span></div>';
    $(singleUpload).closest('.inputWrapper').addClass('inputWrapper--fileupload');
    $(checkboxTrigger).insertAfter(singleUpload);
  }

  $("input[type=file]").on('change', function(){
    let fullPath = $(this).val(),
        startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/')),
        filename = fullPath.substring(startIndex);
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
      filename = filename.substring(1);
    }
    $(this).next('.js-file_upload').find('span').html(filename)
  });

};
