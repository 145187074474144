import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-mousewheel';
import 'malihu-custom-scrollbar-plugin';

export default () => {

  var $filterListSelector = $('.whomakeswhat-filters__list');
  var $whomakeswhatTagLinks = $('.whomakeswhat-tags__link');   
  var $filterWrapper = $('.whomakeswhat-filters__wrapper'); 
  var $categoryInput = $('#category-input');
  var $stateInput = $('#state-input');
  var $resultListSelector = $('.whomakeswhat-result__list');
  var $resultCounterHolderSelector = $('.whomakeswhat-result__total');
  var $searchHintAndImage = $('.whomakeswhat-result__hint, .whomakeswhat-result__hint-image');
  var $emptyResultSelector = $('.whomakeswhat-result__empty, .whomakeswhat-result__empty-image');

  const whomakeswhatFilter = () => {

    // store into hidden input
    const updateHiddenInput = (jqueryElement, newValue) => {
      jqueryElement.val(newValue);
    }

    // return jquery selector of hidden input based on data-input value
    const getHiddenInput = (inputType) => {
      if (inputType === 'category') return $categoryInput;
      else if (inputType === 'state') return $stateInput;
      else console.error("Hidden Input Type not found:", inputType)
    }

    // set new tag selected at dropdown label
    const updateFilterAtLabel = (parentInput, newValue) => {      
      var $valueHolder = parentInput.find('.whomakeswhat-filters__toggle-selected .value');
      $valueHolder.text(newValue);
    }

    // show dropdown label with value
    const showFilterAtLabel = (parentInput) => {
      parentInput.find('.whomakeswhat-filters__toggle-name').hide(); //hide default label
      parentInput.find('.whomakeswhat-filters__toggle-selected').show(); //show new label
    }

    // show dropdown label only
    const showSpecificDefaultLabel = (parentInput) => {
      parentInput.find('.whomakeswhat-filters__toggle-name').show(); //show default label
      parentInput.find('.whomakeswhat-filters__toggle-selected').hide(); //hide new label
    }

    // On click filter tag
    $whomakeswhatTagLinks.on('touch click', function(e) {
      e.preventDefault();

      var $this = $(this);
      var $thisVal = $this.attr('data-tag');
      var $parentSelector = $this.parents('.whomakeswhat-filters__item');
      var $hiddenInput = getHiddenInput($parentSelector.data('input'));
      var previousValue = $hiddenInput.val();
      var $previousSelectedTag = $this.parents('.whomakeswhat-tags__list').find('.whomakeswhat-tags__link--inlist');

      if (!previousValue || !previousValue.length) {
        $this.addClass('whomakeswhat-tags__link--inlist');
        updateHiddenInput($hiddenInput, $thisVal);
        showFilterAtLabel($parentSelector);
        updateFilterAtLabel($parentSelector, $thisVal);
      }
      else if (previousValue !== $thisVal){
        //remove previously selected
        $previousSelectedTag.removeClass('whomakeswhat-tags__link--inlist');
        $this.addClass('whomakeswhat-tags__link--inlist');
        updateHiddenInput($hiddenInput, $thisVal);
        updateFilterAtLabel($parentSelector, $thisVal);
      }
      else {
        //reset selection
        $this.removeClass('whomakeswhat-tags__link--inlist');
        updateHiddenInput($hiddenInput, '');
        showSpecificDefaultLabel($parentSelector);
        updateFilterAtLabel($parentSelector, '');
      }

    });
    
    // On click filter dropdown
    $(".whomakeswhat-filters__toggle").on('click', function(e) {
      var myFilter = $(this);
      // Reset filters if open
      if ($filterListSelector.find('.open').length && !$(myFilter).hasClass('open')) {
        closeFilters();
      }
      // Open right filter
      $(myFilter).toggleClass('open');
      $(myFilter).find('.chevron').toggleClass('open');
      $(myFilter).parent().find('.whomakeswhat-tags__wrapper').toggleClass('open')
    })

    $(document).on('mouseup', (e) => {      
      if (!$filterWrapper.is(e.target) && $filterWrapper.has(e.target).length === 0) {
        closeFilters();
      }
    })
  }

  const whomakeswhatTextSearch = () => {
    // For env other than local
    const textPredictionUrl = location.origin + '/whomakeswhatproxyapi/gettextpredictions';
    // For local testing only
    // const textPredictionUrl = 'https://dairyconsumerdev.growthopssecureapp.com/whomakeswhatproxyapi/gettextpredictions';

    var $productInput = $('#product-input');
    var $companyInput = $('#company-input');
    var $brandInput = $('#brand-input');    
    var productResp, companyResp, brandResp;

    $.get(textPredictionUrl, function(data) {
      productResp = data.Products;
      companyResp = data.Companies;
      brandResp = data.Brands;
    }).done(function () {
      appendToInput($productInput, productResp);
      appendToInput($companyInput, companyResp);
      appendToInput($brandInput, brandResp);
    })

    const appendToInput = (inputSelector, dataSource) => {
      inputSelector.autocomplete({
        source: dataSource,
        appendTo: inputSelector.parent(),
        classes: {
          "ui-autocomplete": 'whomakeswhat-autocomplete'
        },
        open: function(event, ui) {          
          initCustomScrollbar(inputSelector);
        },
        response: function(event, ui) {          
          destroyCustomScrollbar(inputSelector);
        },
        focus: function(event, ui) {
          simulateMouseEnter(inputSelector, ui, this);
        },
        close: function(event, ui) {
          destroyCustomScrollbar(inputSelector);
        }
      });
    }

    const initCustomScrollbar = (inputSelector) => {
      inputSelector.siblings('.whomakeswhat-autocomplete').mCustomScrollbar({
        theme: 'inset-2-dark',
        scrollButtons: {
          enable: false
        }
      });
    }

    const destroyCustomScrollbar = (inputSelector) => {
      inputSelector.siblings('.whomakeswhat-autocomplete').mCustomScrollbar('destroy');
    }

    const simulateMouseEnter = (inputSelector, _ui, _this) => {
      if (!_ui.item) {
        var first = inputSelector.siblings('.whomakeswhat-autocomplete').find("li").first();
        first.trigger("mouseenter");
        $(_this).val(first.data("uiAutocompleteItem").label);
      }
      var el = $(".ui-state-active").parent();
      if (!el.is(":mcsInView") && !el.is(":hover")) {
        inputSelector.siblings('.whomakeswhat-autocomplete').mCustomScrollbar("scrollTo",el,{scrollInertia:0,timeout:0});
      }
    }
  }

  const whomakeswhatFormSubmit = () => {
    var $formSelector = $('#form--whomakeswhat-filters');
    var $resultCounterSelector = $('.whomakeswhat-result__total .value');  
    var $loadingSelector = $('.whomakeswhat-result__loading');
    const formSubmitAPIEndpoint = location.origin + '/whomakeswhatproxyapi/search';
    //local testing
    // const formSubmitAPIEndpoint = 'https://dairyconsumerdev.growthopssecureapp.com/whomakeswhatproxyapi/search';

    $formSelector.on("submit", function(e) {
      e.preventDefault();
      closeFilters();
      emptyResult();
      hideCounter();
      hideResult();
      hideSearchHint();
      hideEmptyResut();
      displayLoading();            
      
      $.post(formSubmitAPIEndpoint, $formSelector.serialize(), function(data) {
        data.forEach(result => {
          appendResult(generateResultItemHTMLString(result));
        })

        updateResultCounter(data.length);
        if (!data.length) showEmptyResult();
        hideLoading();
        showCounter();
        showResult();
      })
    });

    const emptyResult = () => {
      $resultListSelector.empty();
    }

    const hideResult = () => {
      $resultListSelector.hide();
    }

    const hideSearchHint = () => {
      $searchHintAndImage.hide();
    }

    const showCounter = () => {
      $resultCounterHolderSelector.show();
    }

    const displayLoading = () => {
      $loadingSelector.show();
    }

    const hideLoading = () => {
      $loadingSelector.hide();
    }

    const showResult = () => {
      $resultListSelector.show();
    }

    const showEmptyResult = () => {
      $emptyResultSelector.show();
    }

    const updateResultCounter = (counter) => {
      $resultCounterSelector.text(counter);
    }

    const appendResult = (result) => {
      $resultListSelector.append(result)
    }

    const generateResultItemHTMLString = (result) => {
      const companyName = result['CompanyName'];
      const companyWebsite = result['CompanyWebsite'];
      const companyAddress = result['CompanyAddress'];
      const companyBrands = result['BrandsName'];
      const companyProducts = result['ProductName'].map(product => product['ProductName']);
      const companyNameHTMLString = generateCompanyName(companyName);
      const secondColumnHTMLString = generateSecondColumn(companyWebsite, companyAddress);
      const thirdColumnHTMLString = generateThirdColumn(companyBrands, companyProducts);
      const resultItemHTMLString = toEl(companyNameHTMLString + secondColumnHTMLString + thirdColumnHTMLString, 'li', 'whomakeswhat-result__items');
      return resultItemHTMLString;
    }

    const generateCompanyName = (name) => {
      return toEl(name, 'div', 'company-name');
    }

    const generateLabel = (labelName) => {
      return toEl(labelName, 'div', 'label')
    }

    const generateValue = (valueString) => {
      return toEl(valueString, 'div', 'value');
    }

    const generateWebsite = (website) => {
      const label = generateLabel('website');
      const aTag = toATag(website);
      const value = generateValue(aTag);
      const websiteWrapper = toEl(label + value, 'div', 'website');          
      return  websiteWrapper;
    }

    const generateAddress = (address) => {
      const label = generateLabel('address');
      const value = generateValue(address);
      const addressWrapper = toEl(label + value, 'div', 'address');
      return addressWrapper;
    }

    const generateSecondColumn = (website, address) => {
      const content = generateWebsite(website) + generateAddress(address);
      return toEl(content, 'div', 'second-column');
    }

    const generateBrand = (brands) => {
      const label = generateLabel('brands');
      const value = generateValue(brands.join(', '));
      const brandsWrapper = toEl(label + value, 'div', 'brands');
      return brandsWrapper;
    }

    const generateProduct = (products) => {
      const label = generateLabel('products');
      const value = generateValue(products.join(', '));
      const productsWrapper = toEl(label + value, 'div', 'products');
      return productsWrapper;
    }

    const generateThirdColumn = (brands, products) => {
      const content = generateBrand(brands) + generateProduct(products);
      return toEl(content, 'div', 'third-column');
    }

    const toEl = (str, el, className) => {
      if (className) {
        return `<${el} class="${className}">${str}</${el}>`;
      }
      return `<${el}>${str}</${el}>`;
    }

    const toATag = (href) => {
      return `<a href="${href}" target="_blank">${href}</a>`;
    }
    
  }

  const whomakeswhatClearAll = () => {

    var $clearAllSelector = $('.whomakeswhat_filters_form__clear-all');
    var $filterSelectedLabel = $('.whomakeswhat-filters__toggle-selected');
    var $filterDefaultLabel = $('.whomakeswhat-filters__toggle-name');

    const resetForm = () => {
      document.getElementById('form--whomakeswhat-filters').reset();
      $categoryInput.val('');
      $stateInput.val('');
    }

    const resetAndHideFilterLabel = () => {
      $filterSelectedLabel.each(function() {
        $(this).children('.value').text('');
        $(this).hide();
      })
    }

    const showDefaultLabel = () => {
      $filterDefaultLabel.each(function() {
        $(this).show();
      })
    }

    const resetAllSelectedTagLink = () => {
      $whomakeswhatTagLinks.each(function() {
        $(this).removeClass('whomakeswhat-tags__link--inlist');
      })
    }

    $clearAllSelector.on('touch click', function(e) {
      e.preventDefault();
      closeFilters();
      resetForm();
      resetAndHideFilterLabel();
      showDefaultLabel();
      resetAllSelectedTagLink();
      emptyResult();
      hideCounter();
      hideResult();
      hideEmptyResut();
      showSearchHint();
    });
  }

  //common methods
  const closeFilters = () => {
    $filterListSelector.find('.open').removeClass('open');  
  }

  const emptyResult = () => {
    $resultListSelector.empty();
  }

  const hideResult = () => {
    $resultListSelector.hide();
  }

  const hideCounter = () => {
    $resultCounterHolderSelector.hide();
  }

  const showSearchHint = () => {
    $searchHintAndImage.show();
  }

  const hideEmptyResut = () => {
    $emptyResultSelector.hide();
  }

  jQuery(function() {
    $(document).ready(function() {
      whomakeswhatTextSearch();
      whomakeswhatFilter();
      whomakeswhatFormSubmit();
      whomakeswhatClearAll();
    });
  })
};
