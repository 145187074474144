import $ from 'jquery';

export default () => {
  if ($(".recipe_stars").length) {
    for (var containers = 0; containers < $(".recipe_stars").length; containers++) {
      var singleRecipeStarContainer = $(".recipe_stars")[containers]
      for (var index = 0; index < $(singleRecipeStarContainer).data("stars"); index++) {
        let single_star = $(singleRecipeStarContainer).find(".single_star")[index];
        $(single_star).addClass("single_star--full_star");
      }
    }
  }

  $('.rating__star').on('click', (e) => {
    handleRatingSubmission($(e.target).data('rating'))
    $(e.target).addClass('selected')
  })

};

const handleRatingSubmission = (rating) => {
  const url = $('#ratingsTitleHolder').data('hostname') + 'recipe/api/RateRecipe/';
  const formData = {
    id: $('#ratingsTitleHolder').data('recipe-id'),
    rating: rating,
  };

  $.ajax({
    type: 'POST',
    url: url,
    data: formData,
    dataType: 'json',
    success: () => {
      $('.rating__label').html('Thank you for voting!');
      $('.rating__star-container').addClass('rating--disabled');
    },
    error: (error) => {
      console.log(error)
    }
  })
}
