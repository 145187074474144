import $ from 'jquery';
import '../vendors/sticky-kit.js';

export default () => {
  $(window).on('resize', function(){
    $('[data-stick-to-bottom]').each(function() {
      const offsetHeight = $(window).height() - $(this).outerHeight();
      $(this).stick_in_parent({
        offset_top: offsetHeight,
        spacer: false,
      });
    });
  }).trigger('resize');
};
