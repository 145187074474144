import $ from 'jquery';
import Velocity from 'velocity-animate';

export default () => {
  $(".nav__hamburger_wrapper").on('click', (e) => {
    $(".nav__title").velocity({
      opacity: 0
    }, {
      duration: 200,
      complete: function() {
        if ($(".nav__hamburger").hasClass("open")) {
          $(".nav__title").html("Close").velocity("reverse");
        } else {
          $(".nav__title").html("Menu").velocity("reverse");
        }
      }
    })
    $(".nav__hamburger").toggleClass('open');
    $(".nav__list").toggleClass('open');
  });
  $(".nav__mobile-opener").on('click', (e) => {
    e.preventDefault();
    let mySubNav = $(e.target).closest(".nav__item").find(".nav__secondary-list");
    let myChevron = $(e.target).closest(".nav__item").find(".chevron");
    let myElement = $(e.target).closest(".nav__item").find(".nav__link");
    $(myElement).toggleClass('open');
    $(myChevron).toggleClass('open');
    $(mySubNav).toggleClass('open');
  })
  $(".nav__mobile-search").on('click', (e) => {
    $(".nav__search").toggleClass("open");
    $(".nav__mobile-search").toggleClass("open");
  })
};
