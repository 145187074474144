import $ from 'jquery';
import 'jquery-ui-bundle';

export default () => {
  if ($("#tabs").length) {
    $("#tabs").tabs();
    // $('.tabs_header__link').on('click', (e) => {
    //   $(".tabs_header__list").animate({scrollLeft: $(e.target).position().left}, 500);
    // })
  }
};
