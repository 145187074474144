import $ from 'jquery';
import Swiper from 'swiper/dist/js/swiper.min.js'

export default () => {
  if ($(".swiper-container").length) {
    var mySwiper = new Swiper ('.swiper-container', {
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  }
};
