// Ref: https://github.com/lgarron/clipboard-polyfill
import $ from 'jquery';
import * as clipboard from 'clipboard-polyfill/build/clipboard-polyfill.promise';

export default () => {
  $('[data-copy-link]').on('click', (event) => {
    event.preventDefault();
    const scroll = $(window).scrollTop();
    clipboard.writeText(window.location.href);
    
    const wrapper = event.target.parentNode.querySelector('span');
    const text = wrapper.innerText;
    wrapper.innerText = 'Copied!';
    setTimeout(() => {
      wrapper.innerText = text;
    }, 4000);

    // Fix for IEdge jumping
    $(window).scrollTop(scroll);
  });
  
};