import $ from 'jquery';

export default () => {
  var checkBoxes = $('.recipes-filters__checkbox');

  function getQueryVariable(key) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    let match = false;
    vars.map(function(segment){
      const pair = segment.split('=');
      if(pair[0] == key) {
        match = pair[1];
      }
    });
    return(decodeURIComponent(match));
  }

  function toggleCheckedBoxes(exceptionId) {

    checkBoxes.each( function() {
      const $this = $(this);

      if( $this.attr('id') !== exceptionId ){
        $this.prop("checked", false);
      }
    });

  }

  const recipeFilter = () => {

    checkBoxes.on('touch click', function(){
      toggleCheckedBoxes($(this).attr('id'));
    });

    var $recipeTagLinks = $('.recipe-tags__link');
    var $recipeFilterList = []; //array to test for tags added
    var $hiddenInput = $('.recipes_filters_form__current-filters'); //where to store tag choice values
    if ($('.recipes_filters_form__current-filters').lenght) {
      if ($('.recipes_filters_form__current-filters').val().length) {
        $recipeFilterList = $('.recipes_filters_form__current-filters').val().split(',');
        for (var singleFilter of $recipeFilterList) {
          $('.recipe-tags__link--' + singleFilter).addClass('recipe-tags__link--inlist');
        }
      }
    }
    
    // method for pushing value to hidden input
    const updateInput = () => {
      $hiddenInput.val($recipeFilterList); //add new value to input
      // $difficulityVal.text($recipeFilterList); // change value on sort element
    }

    // method for adding chosen tag to test array
    const addToFilterList = (item) => {
      $recipeFilterList.push(item);
      updateInput();
    }

    // method for removine chosen tag to test array
    const removeFromFilterList = (item) => {
      $recipeFilterList.splice( $recipeFilterList.indexOf(item), 1 );
      updateInput();
    }

    $('#sort-form-select').on('change', () => {
      $('#form--recipes_filters').submit();
    })


    $('.recipes_filters_form__clear-all').on('touch click', function(e) {
      e.preventDefault();
      $recipeFilterList = [];
      updateInput();
      $recipeTagLinks.removeClass('recipe-tags__link--inlist');
      $('#form--recipes_filters').submit();
    });

    // touch click event on the tag
    $recipeTagLinks.on('touch click', function(e) {
      e.preventDefault();

      var $this = $(this); // get object
      var $thisVal = $this.attr('data-tag'); // get array in data attribute

      if( $recipeFilterList.indexOf($thisVal) === -1 ){
        addToFilterList($thisVal);
        $this.addClass('recipe-tags__link--inlist');
      }else {
        $this.removeClass('recipe-tags__link--inlist');
        removeFromFilterList($thisVal);
      }

    });

    // Initialise selected filters from querystring
    const categories = getQueryVariable('category').split(',');
    $('.recipe-tags__link').each(function(){
      if(categories.indexOf($(this).attr('data-tag')) > -1) {
        addToFilterList($(this).attr('data-tag'));
        $(this).addClass('recipe-tags__link--inlist');
      }
    });

  }

  const closeFilters = () => {
    $('.recipes-filters__list').find('.open').removeClass('open');
  }

  $(document).ready(function() {
    recipeFilter();
  });

  $(document).on('mouseup', (e) => {
    var container = $('.recipes-filters__wrapper');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      closeFilters();
    }
  })

  $(".recipes-filters__toggle").on('click', (e) => {
    var myFilter = $(e.target);
    // Reset filters if open
    if ($('.recipes-filters__list').find('.open').length && !$(myFilter).hasClass('open')) {
      closeFilters();
    }
    // Open right filter
    $(myFilter).toggleClass('open');
    $(myFilter).find('.chevron').toggleClass('open');
    $(myFilter).parent().find('.recipes-tags__wrapper').toggleClass('open')
  })


};
