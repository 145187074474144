// Libraries
import jQuery from 'jquery';
window.jQuery = jQuery;
window.$ = jQuery;

// Atoms
import MainContent from './atoms/main_content';
import Heading from './atoms/heading';
import Star from './atoms/star';
import LineIcon from './atoms/line_icon';
import PrintPage from './atoms/print_page';
import CopyLink from './atoms/copy_link';
import Sticky from './atoms/sticky';
import Tables from './atoms/tables';
import YawaUseful from './atoms/yawa_useful';
import Search from './atoms/search';
// Molecules
import RecipeFilter from './molecules/recipe_filter';
import Accordion from './molecules/accordion';
import Forms from './molecules/forms';
import AgdaVoting from './molecules/agda_voting';
import WhoMakesWhatFilter from './molecules/whomakeswhat_filter';
// Organisms
import Carousel from './molecules/carousel';
import Tabs from './molecules/tabs';
import Nav from './molecules/nav';
import Yawa from './molecules/yawa';
// Polfills
import Polyfill from './polyfills/polyfill';
import objectFitImages from 'object-fit-images';
// Vendors
import Modernizr from './vendors/modernizr';

// YAWA SEACH REACT
import './react/search-container.jsx';

// Init all
$(() => {

  MainContent();
  Heading();
  Star();
  LineIcon();
  RecipeFilter();
  Accordion();
  Carousel();
  Tabs();
  Nav();
  Yawa();
  PrintPage();
  CopyLink();
  Sticky();
  Tables();
  Forms();
  YawaUseful();
  Search();
  AgdaVoting();
  WhoMakesWhatFilter();

  objectFitImages();
  Polyfill();
});

