import $ from 'jquery';

export default () => {
  const buttons = $('[data-yawa-useful]');
  const question = $('[data-yawa-useful-question]');
  const response = $('[data-yawa-useful-response]');
  buttons.on('click', function(){
    $.post($(this).attr('data-yawa-useful'));
    question.fadeOut('fast', function(){
        response.fadeIn();
    });
  });
};
